import mock from '@/fake-db/mock.js'

var data = {
  hobjects: [
    // {
    //   'id': 1,
    //   'category': 'computers',
    //   'img': 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/products/01.png',
    //   'name': 'Apple Watch series 4 GPS',
    //   'order_status': 'on_hold',
    //   'popularity': 97,
    //   'price': 69.99
    // }
  ],
}

export function setHobjects(hobjects) {
  data.hobjects = hobjects
}

mock.onGet('/api/data-list/products').reply(() => {
  return [200, JSON.parse(localStorage.getItem('hobjects'))]
})

// POST : Add new Item
// mock.onPost('/api/data-list/products/').reply((request) => {

//   // Get event from post data
//   const item = JSON.parse(request.data).item

//   const length = data.products.length
//   let lastIndex = 0
//   if (length) {
//     lastIndex = data.products[length - 1].id
//   }
//   item.id = lastIndex + 1

//   data.products.push(item)

//   return [201, {id: item.id}]
// })

// Update Product
// mock.onPost(/\/api\/data-list\/products\/\d+/).reply((request) => {

//   const itemId = request.url.substring(request.url.lastIndexOf('/') + 1)

//   const item = data.products.find((item) => item.id == itemId)
//   Object.assign(item, JSON.parse(request.data).item)

//   return [200, item]
// })

// DELETE: Remove Item
// mock.onDelete(/\/hobject\/\d+/).reply((request) => {

// const hobjectPid = request.url.substring(request.url.lastIndexOf('/') + 1)

// const itemIndex = data.products.findIndex((h) => h.pid == hobjectPid)
// data.products.splice(itemIndex, 1)
// return [200]
// })
