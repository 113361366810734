import { DirectUpload } from '@rails/activestorage'

export default class Uploader {
  constructor(file, url, controller) {
    this.file = file
    this.url = url
    this.controller = controller
    this.directUpload = new DirectUpload(this.file, this.url, this)
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          // Handle the error
          reject(error)
        } else {
          // Add an appropriately-named hidden input to the form
          // with a value of blob.signed_id
          resolve(blob.signed_id)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
    let percent = ((event.loaded / event.total) * 100).toFixed(1)
    if (percent >= 100) document.getElementsByClassName('title-loading')[0].innerText = 'Finalizing...'
    else document.getElementsByClassName('title-loading')[0].innerText = 'Uploading ' + parseInt(percent) + '%'
    console.log(percent)
    // progress display
    // 更新全局进度条(iview)
    // this.vm.$Loading.update(percent)
  }
}
