import mock from '@/fake-db/mock.js'

const data = {
  create_basic_card_1: {
    img: require('@/assets/images/pages/create/wallart.png'),
    title: 'Wall Art',
    subtitle: 'Create a twin of your artwork that can be placed on any wall',
  },
  create_basic_card_2: {
    img: require('@/assets/images/pages/create/cutout.png'),
    title: 'Pop-up Image',
    subtitle: 'Turn a semi-transparent image into an ar pop-up image',
  },
  create_basic_card_3: {
    img: require('@/assets/images/pages/create/hologram.png'),
    title: 'Hologram',
    subtitle: 'Turn a green screen video into a hologram',
  },
  create_basic_card_4: {
    img: require('@/assets/images/pages/create/links.png'),
    title: 'Hologram',
    subtitle: 'Turn a green screen video into a hologram',
  },
}

mock.onGet('/api/create-basic-card/1').reply(() => {
  return [200, data.create_basic_card_1]
})

mock.onGet('/api/create-basic-card/2').reply(() => {
  return [200, data.create_basic_card_2]
})

mock.onGet('/api/create-basic-card/3').reply(() => {
  return [200, data.create_basic_card_3]
})

mock.onGet('/api/create-basic-card/4').reply(() => {
  return [200, data.create_basic_card_4]
})
