// axios
import axios from 'axios'

// LOCALHOST
//const baseURL = 'http://127.0.0.1:8081/'

// STAGING
// const baseURL = 'https://hov-extended-api-stag.herokuapp.com/'

// PROD
const baseURL = 'https://hov-extended-api-prod.herokuapp.com/'
export default axios.create({
  baseURL,
  // You can add your headers here
})
