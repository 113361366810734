/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'

export default {
  RESET(state, _) {
    console.log('RESET')
    state.hobjects = []
    state.nodes = []
    state.placements = []
    state.anchors = []
    state.models = []
    state.spaces = []
    state.labels = []
  },
  ADD_LABEL(state, label) {
    state.labels.unshift(label)
  },
  UPDATE_LABEL(state, label) {
    const labelPid = state.labels.findIndex(p => p.pid === label.pid)
    Object.assign(state.labels[labelPid], label)
  },
  REMOVE_LABEL(state, labelPid) {
    const lPid = state.labels.findIndex(p => p.pid === labelPid)
    state.labels.splice(lPid, 1)
  },
  SET_LABEL(state, labels) {
    if (labels) state.labels = labels
  },
  SET_SPACE_LABEL(state, spaces_labels) {
    if (spaces_labels) state.spaces_labels = spaces_labels
  },
  async ADD_HOBJECT(state, hobject) {
    hobject.updated_at_timestamp = new Date(hobject.updated_at).getTime()
    // Lower abilities
    if (hobject.abilities) hobject.abilities = hobject.abilities.toLowerCase()
    // Add content_length if not already defined
    try {
      if (hobject.content_length == 0 && hobject.asset_uri)
        hobject.content_length = await HoverlayUtils.getUrlContentLength(hobject.asset_uri)
    } catch (e) {
      console.log(e)
    }
    state.hobjects.unshift(hobject)
  },
  async SET_HOBJECT(state, hobjects) {
    for (const hobject of hobjects) {
      try {
        if (hobject.content_length == 0 && hobject.asset_uri)
          hobject.content_length = await HoverlayUtils.getUrlContentLength(hobject.asset_uri)
      } catch (e) {}
      hobject.updated_at_timestamp = new Date(hobject.updated_at).getTime()
    }
    if (hobjects) state.hobjects = hobjects
  },
  async UPDATE_HOBJECT(state, hobject) {
    hobject.updated_at_timestamp = new Date(hobject.updated_at).getTime()
    const hobject_pid = state.hobjects.findIndex(p => p.pid === hobject.pid)
    // Add content_length if not already defined
    try {
      if (hobject.content_length == 0 && hobject.asset_uri)
        hobject.content_length = await HoverlayUtils.getUrlContentLength(hobject.asset_uri)
    } catch (e) {}
    Object.assign(state.hobjects[hobject_pid], hobject)
  },
  ADD_OR_UPDATE_HOBJECT(state, hobject) {
    const hobject_pid = state.hobjects.findIndex(p => p.pid === hobject.pid)
    if (hobject_pid != -1) Object.assign(state.hobjects[hobject_pid], hobject)
    else state.hobjects.unshift(hobject)
  },
  ADD_OR_UPDATE_HOBJECTS(state, hobjects) {
    hobjects.forEach(hobject => {
      const hobject_pid = state.hobjects.findIndex(p => p.pid === hobject.pid)
      if (hobject_pid != -1) Object.assign(state.hobjects[hobject_pid], hobject)
      else state.hobjects.unshift(hobject)
    })
  },
  REMOVE_HOBJECT(state, hobject_pid) {
    const hPid = state.hobjects.findIndex(p => p.pid === hobject_pid)
    state.hobjects.splice(hPid, 1)
  },

  // ANCHORS
  ADD_ANCHOR(state, anchor) {
    state.anchors.unshift(anchor)
  },
  SET_ANCHOR(state, anchors) {
    if (anchors) state.anchors = anchors
  },
  UPDATE_ANCHOR(state, anchor) {
    const hobject_pid = state.anchors.findIndex(p => p.pid === anchor.pid)
    Object.assign(state.anchors[hobject_pid], anchor)
  },
  ADD_OR_UPDATE_ANCHOR(state, anchor) {
    const anchor_pid = state.anchors.findIndex(p => p.pid === anchor.pid)
    if (anchor_pid != -1) Object.assign(state.anchors[anchor_pid], anchor)
    else state.anchors.unshift(anchor)
  },
  ADD_OR_UPDATE_ANCHORS(state, anchors) {
    anchors.forEach(anchor => {
      const anchor_pid = state.anchors.findIndex(p => p.pid === anchor.pid)
      if (anchor_pid != -1) Object.assign(state.anchors[anchor_pid], anchor)
      else state.anchors.unshift(anchor)
    })
  },
  REMOVE_ANCHOR(state, anchor_pid) {
    const pid = state.anchors.findIndex(p => p.pid === anchor_pid)
    state.anchors.splice(pid, 1)
  },
  SET_ANCHORS_SPACES(state, anchors_spaces) {
    if (anchors_spaces) state.anchors_spaces = anchors_spaces
  },
  ADD_ANCHOR_SPACE(state, anchor_space) {
    state.anchors_spaces.unshift(anchor_space)
  },
  REMOVE_ANCHOR_SPACE(state, params) {
    const anchor_space = state.anchors_spaces.findIndex(
      a => a.anchor.pid === params['anchor_pid'] && a.space_pid === params['space_pid']
    )
    state.anchors_spaces.splice(anchor_space, 1)
  },
  ADD_SPACE_LABEL(state, space_label) {
    state.spaces_labels.unshift(space_label)
  },
  REMOVE_SPACE_LABEL(state, params) {
    const space_label = state.spaces_labels.findIndex(
      a => a.label.pid === params['label_pid'] && a.space_pid === params['space_pid']
    )
    state.spaces_labels.splice(space_label, 1)
  },
  // MODElS
  ADD_MODEL(state, model) {
    console.log('ADD_MODEL')
    state.models.unshift(model)
  },
  SET_MODEL(state, models) {
    console.log('SET_MODEL')
    if (models) state.models = models
  },
  UPDATE_MODEL(state, model) {
    const hobject_pid = state.models.findIndex(p => p.pid === model.pid)
    Object.assign(state.models[hobject_pid], model)
  },
  ADD_OR_UPDATE_MODELS(state, models) {
    models.forEach(model => {
      const model_pid = state.models.findIndex(p => p.pid === model.pid)
      if (model_pid != -1) Object.assign(state.models[model_pid], model)
      else state.models.unshift(model)
    })
  },
  REMOVE_MODEL(state, modelPid) {
    const pid = state.models.findIndex(p => p.pid === modelPid)
    state.models.splice(pid, 1)
  },

  // PLACEMENTS
  ADD_PLACEMENT(state, placement) {
    state.placements.unshift(placement)
  },
  SET_PLACEMENT(state, placements) {
    // Filter only active placement
    // var activePlacements = placements.filter(p => new Date(p.present_to) > new Date())
    if (placements) state.placements = placements
  },
  UPDATE_PLACEMENT(state, placement) {
    console.log('UPDATE_PLACEMENT ' + state)
    console.log(placement)

    const placementPid = state.placements.findIndex(p => p.pid === placement.pid)
    Object.assign(state.placements[placementPid], placement)
  },
  REMOVE_PLACEMENT(state, placementPid) {
    console.log('REMOVE_PLACEMENT ' + placementPid)
    const hPid = state.placements.findIndex(p => p.pid === placementPid)
    state.placements.splice(hPid, 1)
  },

  // SPACES
  ADD_SPACE(state, space) {
    space.updated_at_timestamp = new Date(space.updated_at).getTime()
    state.spaces.unshift(space)
  },
  SET_SPACE(state, spaces) {
    // Filter only active space
    // var activeSpaces = spaces.filter(p => new Date(p.present_to) > new Date())
    spaces.forEach(space => {
      space.updated_at_timestamp = new Date(space.updated_at).getTime()
    })
    if (spaces) state.spaces = spaces
  },
  UPDATE_SPACE(state, space) {
    space.updated_at_timestamp = new Date(space.updated_at).getTime()
    const space_pid = state.spaces.findIndex(p => p.pid === space.pid)
    Object.assign(state.spaces[space_pid], space)
  },
  ADD_OR_UPDATE_SPACES(state, spaces) {
    console.log('state.spaces. ' + state.spaces)
    console.log(state.spaces)
    spaces.forEach(space => {
      space.updated_at_timestamp = new Date(space.updated_at).getTime()
      const space_pid = state.spaces.findIndex(p => p.pid === space.pid)
      if (space_pid != -1) Object.assign(state.spaces[space_pid], space)
      else state.spaces.unshift(space)
    })
  },
  ADD_OR_UPDATE_SPACE(state, space) {
    space.updated_at_timestamp = new Date(space.updated_at).getTime()
    const space_pid = state.spaces.findIndex(p => p.pid === space.pid)
    if (space_pid != -1) Object.assign(state.spaces[space_pid], space)
    else state.spaces.unshift(space)
  },

  REMOVE_SPACE(state, space_pid) {
    console.log('REMOVE_SPACE ' + space_pid)
    const hPid = state.spaces.findIndex(p => p.pid === space_pid)
    state.spaces.splice(hPid, 1)
  },

  // NODE
  ADD_NODE(state, node) {
    state.nodes.unshift(node)
  },
  SET_NODE(state, nodes) {
    if (nodes) state.nodes = nodes
  },
  UPDATE_NODE(state, node) {
    const nodePid = state.nodes.findIndex(p => p.pid === node.pid)
    Object.assign(state.nodes[nodePid], node)
  },
  ADD_OR_UPDATE_NODE(state, node) {
    const nodePid = state.nodes.findIndex(p => p.pid === node.pid)
    if (nodePid != -1) Object.assign(state.nodes[nodePid], node)
    else state.nodes.unshift(node)
  },
  REMOVE_NODES_FROM_SPACE(state, spacePid) {
    state.nodes.forEach(node => {
      if (node.space_pid == spacePid) {
        const hPid = state.nodes.findIndex(p => p.pid === node.pid)
        state.nodes.splice(hPid, 1)
      }
    })
  },
  ADD_OR_UPDATE_NODES(state, nodes) {
    nodes.forEach(node => {
      const nodePid = state.nodes.findIndex(p => p.pid === node.pid)
      if (nodePid != -1) Object.assign(state.nodes[nodePid], node)
      else state.nodes.unshift(node)
    })
  },
  REMOVE_NODE(state, nodePid) {
    console.log('REMOVE_NODE ' + nodePid)
    const hPid = state.nodes.findIndex(p => p.pid === nodePid)
    state.nodes.splice(hPid, 1)
  },
  REMOVE_NODES_THAT_BELONG_TO_THIS_SPACE(state, spacePid) {
    console.log('REMOVE_NODES_THAT_BELONG_TO_THIS_SPACE')
    state.nodes.forEach(node => {
      console.log(node.space_pid)
      console.log(spacePid)
      if (node.space_pid == spacePid) {
        state.nodes.splice(node.pid, 1)
        console.log('REMOVE_NODE ' + node.pid)
      }
    })
  },
  UPDATE_PREFERENCES(state, preferences) {
    if (!state.preferences) state.preferences = {}
    state.preferences[Object.keys(preferences)[0]] = Object.values(preferences)[0]
  },
}
