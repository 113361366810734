/*=========================================================================================
  File Name: moduleHoverlayDataActions.js
  Description: Hoverlay Module Actions
==========================================================================================*/

// import axios from '@/axios.js'
import axios from '../../http/axios/index.js'
import SwaggerClient from 'swagger-client'
import { SwaggerDefinition, ApiKey } from '@/apiConfig.js'
import store from '@/store/store.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import * as Utils from '@/assets/js/utils/utils.js'

// const camelcaseKeys = require('camelcase-keys')
// var snakecaseKeys = require('snakecase-keys')

function getBearer() {
  return localStorage.getItem('accessToken')
}

export default {
  removeAll({ commit }) {
    commit('SET_PLACEMENT', [])
    commit('SET_NODE', [])
    commit('SET_SPACE', [])
    commit('SET_ANCHOR', [])
    commit('SET_HOBJECT', [])
    commit('SET_MODEL', [])
  },
  removeNode({ commit }, pid) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'removeNode',
      parameters: { pid: pid },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('REMOVE_NODE', pid)
    })
  },
  removeHobject({ commit }, params) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'deleteHobject',
      parameters: { pid: params.hobject_pid },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('REMOVE_HOBJECT', params.hobject_pid)
      if (params.nodePid) commit('REMOVE_NODE', params.nodePid)
    })
  },
  removeAnchor({ commit }, params) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'deleteAnchor',
      parameters: { pid: params.anchor_pid },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('REMOVE_ANCHOR', params.anchor_pid)
    })
  },
  compressGlb(_, file) {
    var formData = new FormData()
    formData.append('glb', file)
    return new Promise((resolve, reject) => {
      axios
        .post(`/tools/compress-glb`, formData, {
          onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            if (percentCompleted >= 100)
              document.getElementsByClassName('title-loading')[0].innerText = 'Finalizing Compression...'
            else
              document.getElementsByClassName('title-loading')[0].innerText =
                'Compressing ' + parseInt(percentCompleted) + '%'
            console.log(percentCompleted)
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob', // important
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  askS3permission(_, file) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/s3/sign?file-type=${file.type}&layer-name=${
            JSON.parse(localStorage.getItem('layerInfo')).name
          }&file-ext=${file.name.split('.').pop()}`
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  uploadToS3(_, data) {
    return new Promise((resolve, reject) => {
      console.log('uploadToS3uploadToS3')
      console.log(data)

      delete axios.defaults.headers['Authorization']

      axios
        .put(data.signedRequest, data.file, {
          headers: {
            'Content-Type': data.file.type,
          },
          // onUploadProgress: progressEvent => {},
          // onDownloadProgress: progressEvent => {},
        })
        .then(response => {
          console.log('response')
          console.log(response)
          axios.defaults.headers['Authorization'] = localStorage.getItem('accessToken')
          resolve(response)
        })
        .catch(error => {
          axios.defaults.headers['Authorization'] = localStorage.getItem('accessToken')
          reject(error)
        })
    })
  },
  addHobject({ commit }, hobject) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'postHobject',
        requestBody: hobject,
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_HOBJECT', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  getHobject({ commit }, hobject_pid) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'getHobject',
        parameters: { pid: hobject_pid },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_OR_UPDATE_HOBJECT', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  updateHobject({ commit }, hobject) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'putHobject',
        parameters: { pid: hobject.pid },
        requestBody: Utils.cleanupNullKeys(hobject),
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('UPDATE_HOBJECT', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  addAnchor({ commit }, anchor) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'postAnchor',
        requestBody: anchor,
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_ANCHOR', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  postLabel({ commit }, label) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'postLabel',
        requestBody: label,
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_LABEL', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  putLabel({ commit }, label) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'putLabel',
        parameters: { pid: label.pid },
        requestBody: Utils.cleanupNullKeys(label),
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('UPDATE_LABEL', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  removeLabel({ commit }, label_pid) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'removeLabel',
        parameters: { pid: label_pid },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('REMOVE_LABEL', label_pid)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  AddLabelToSpace({ commit }, params) {
    console.log(params)
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'AddLabelToSpace',
        parameters: { pid: params['space_pid'], label_pid: params['label_pid'] },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_SPACE_LABEL', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  RemoveLabelToSpace({ commit }, params) {
    console.log(params)
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'RemoveLabelToSpace',
        parameters: { pid: params['space_pid'], label_pid: params['label_pid'] },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('REMOVE_SPACE_LABEL', { label_pid: params['label_pid'], space_pid: params['space_pid'] })
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  getAnchorSpace({ commit }, space_pid) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'getAnchorSpace',
        parameters: { pid: space_pid },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('SET_ANCHORS_SPACES', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  addAnchorSpace({ commit }, params) {
    console.log(params)
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'AddAnchorToSpace',
        parameters: { anchor_pid: params['anchor_pid'], pid: params['space_pid'] },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_ANCHOR_SPACE', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  removeAnchorSpace({ commit }, params) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'RemoveAnchorToSpace',
        parameters: { anchor_pid: params['anchor_pid'], pid: params['space_pid'] },
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('REMOVE_ANCHOR_SPACE', { anchor_pid: params['anchor_pid'], space_pid: params['space_pid'] })
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  updateAnchor({ commit }, anchor) {
    return new Promise((resolve, reject) => {
      console.log('updateAnchor')
      console.log(anchor)
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'putAnchor',
        parameters: { pid: anchor.pid },
        requestBody: anchor,
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('UPDATE_ANCHOR', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  deleteSpace({ commit }, pid) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'removeSpace',
      parameters: { pid: pid },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('REMOVE_SPACE', pid)
    })
  },
  createNodeInSpace({ commit }, payload) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'addNodeToSpace',
      parameters: payload,
      requestBody: payload,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('ADD_NODE', res.body)
    })
  },
  createSpace({ commit }, SpaceWorkflowItem) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'newSpace',
        requestBody: SpaceWorkflowItem,
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('ADD_OR_UPDATE_SPACE', res.body.space)
          commit('ADD_OR_UPDATE_HOBJECT', res.body.hobject)
          commit('ADD_OR_UPDATE_NODE', res.body.node)
          commit('ADD_OR_UPDATE_ANCHOR', res.body.anchor)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  fetchModel({ commit }, ModelParams) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getModels',
      parameters: ModelParams,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      res.body.forEach(m => {
        if (m.abilities) m.abilities = m.abilities.toLowerCase()
        commit('ADD_MODEL', m)
      })
    })
  },

  getAnchor({ commit }, pid) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getAnchor',
      parameters: { pid: pid },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('ADD_ANCHOR', res.body)
    })
  },

  fetchAnchor({ commit }, anchor_type) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getAnchorOfType',
      parameters: { type: anchor_type },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('ADD_ANCHOR', res.body[0])
    })
  },
  getLayerSpaces({ commit }, layer_pid) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getSpaces',
      parameters: { pid: layer_pid },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('SET_SPACE', res.body)
    })
  },
  getSpaceContent({ commit }, params) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getSpaceContent',
      parameters: params,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('REMOVE_NODES_FROM_SPACE', res.body.pid)
      commit('ADD_OR_UPDATE_NODES', res.body.nodes)
      commit('ADD_OR_UPDATE_ANCHORS', res.body.anchors)
      commit('ADD_OR_UPDATE_HOBJECTS', res.body.hobjects)
      return res.body
    })
  },
  getUserAnchors({ commit }) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getUserAnchors',
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('ADD_OR_UPDATE_ANCHORS', res.body)
    }) // => Promise.<Response>
  },
  getUserLayerSpaces({ commit }, params) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getUserLayerSpaces',
      parameters: params,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      commit('SET_SPACE', res.body)
    })
  },
  async getUserHobjects({ commit }) {
    var res = await SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getUserHobjects',
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
    commit('SET_HOBJECT', res.body)
    return res
  },
  async getUserLabels({ commit }) {
    var res = await SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getUserLabels',
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
    commit('SET_LABEL', res.body.labels)
    commit('SET_SPACE_LABEL', res.body.space_labels)
    return res
  },
  async getLayerContent({ commit }) {
    var res = await SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getUserContent',
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
    commit('SET_HOBJECT', res.body.hobjects)
    commit('SET_SPACE', res.body.spaces)
    commit('SET_NODE', res.body.nodes)
    commit('ADD_OR_UPDATE_ANCHORS', res.body.anchors)
    commit('ADD_OR_UPDATE_MODELS', res.body.models)
  },
  updateNodes({ commit }, nodes) {
    var promisesArray = []
    nodes.forEach(node => {
      node.is_dirty = false
      promisesArray.push(
        SwaggerClient.execute({
          spec: SwaggerDefinition,
          operationId: 'updateNode',
          parameters: node,
          securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
        }).then(res => {
          commit('UPDATE_NODE', res.body)
        })
      )
    })
    return Promise.all(promisesArray)
  },
  updateSpace({ commit }, space) {
    return new Promise((resolve, reject) => {
      // Do not send thumbnail as data64 string
      if (typeof space.thumbnail === 'string' || space.thumbnail instanceof String) delete space.thumbnail
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'updateSpace',
        parameters: { pid: space.pid },
        requestBody: Utils.cleanupNullKeys(space),
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          commit('UPDATE_SPACE', res.body)
          resolve(res.body)
        })
        .catch(e => reject(e))
    })
  },
  getAuthenticatedUser({ commit }) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'getAuthenticatedUser',
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          if (res.body) {
            localStorage.setItem('userInfo', JSON.stringify(res.body))
            resolve(res.body)
          }
          store.commit('UPDATE_USER_INFO', res.body)
        })
        .catch(e => reject(e))
    })
  },
  confirmAccount({ commit }, token) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'confirmAccount',
        parameters: token,
        securities: { authorized: { PrivateKey: ApiKey } },
      })
        .then(res => {
          resolve(res)
        })
        .catch(e => reject(e))
    })
  },
  sendAccountConfirmationEmail({ commit }, params) {
    return new Promise((resolve, reject) => {
      return SwaggerClient.execute({
        spec: SwaggerDefinition,
        operationId: 'sendAccountConfirmationEmail',
        parameters: params,
        securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
      })
        .then(res => {
          resolve(res)
        })
        .catch(e => reject(e))
    })
  },
  copySpace({ commit }, params) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'copySpace',
      parameters: params,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    }).then(res => {
      if (params.destination) commit('REMOVE_NODES_THAT_BELONG_TO_THIS_SPACE', params.destination)
      // commit('ADD_OR_UPDATE_SPACE', res.body)
    })
  },
  getReferenceImageScore(_, data64) {
    delete axios.defaults.headers['Authorization']

    var img = `{ "img": "${data64}" }`
    const payload = {
      body: img,
    }
    console.log('payload')
    console.log(payload)

    return new Promise((resolve, reject) => {
      axios
        .post(`https://8m78ghqa9i.execute-api.us-east-1.amazonaws.com/prod`, payload)
        .then(response => {
          console.log(response.data)
          axios.defaults.headers['Authorization'] = localStorage.getItem('accessToken')
          resolve(response.data)
        })
        .catch(error => {
          axios.defaults.headers['Authorization'] = localStorage.getItem('accessToken')
          reject(error)
        })
    })
  },
  getLatLongFromAddress(_, address) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&polygon=1&addressdetails=1`)
        .then(response => {
          console.log(response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getAddressFromLatLong(_, placement) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${placement.latitude}&lon=${placement.longitude}&zoom=18&addressdetails=1`
        )
        .then(response => {
          if (response.data.display_name) resolve(response.data.display_name)
          else reject(response.error)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateSpaceListView({ commit }, is_space_list_view_enabled) {
    commit('UPDATE_PREFERENCES', { is_space_list_view_enabled: is_space_list_view_enabled })
  },
}
