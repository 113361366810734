import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'

import SwaggerClient, { resolve } from 'swagger-client'
import { SwaggerDefinition, ApiKey } from '@/apiConfig.js'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

function getBearer() {
  return localStorage.getItem('accessToken')
}

export default {
  init() {
    axios.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true
            store.dispatch('auth/fetchAccessToken').then(access_token => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
          }

          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = `Bearer ${access_token}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  },
  updateLayer(layer) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'putLayer',
      parameters: { pid: layer.pid },
      requestBody: layer,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
  },
  login(email, password) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'login',
      requestBody: { auth: { email, password } },
      securities: { authorized: { PrivateKey: ApiKey } },
    })
  },
  registerUser(username, email, password) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'postUser',
      requestBody: {
        username,
        email,
        password,
        layer_type: 'spaces',
        redirect_url: 'https://spaces.hoverlay.com/account-confirmation',
      },
      securities: { authorized: { PrivateKey: ApiKey } },
    })
  },
  forgotPassword(params) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'forgotPassword',
      requestBody: params,
      securities: { authorized: { PrivateKey: ApiKey } },
    })
  },
  newPassword(payload) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'newPassword',
      requestBody: payload,
      securities: { authorized: { PrivateKey: ApiKey } },
    })
  },
  getUser() {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'getAuthenticatedUser',
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
  },
  updateUser(user) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'putAuthenticatedUser',
      parameters: { pid: user.pid },
      requestBody: user,
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
  },
  updateUserPhoto(photo) {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'putAuthenticatedUser',
      requestBody: { photo: photo },
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
  },
  deleteUserPhoto() {
    return SwaggerClient.execute({
      spec: SwaggerDefinition,
      operationId: 'deleteAuthenticatedUserPhoto',
      securities: { authorized: { PrivateKey: ApiKey, Bearer: getBearer() } },
    })
  },
  refreshToken() {
    return axios.post('/api/auth/refresh-token', { accessToken: localStorage.getItem('accessToKen') })
  },
}
