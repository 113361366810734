/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '../../http/axios/index.js'

if (axios.defaults.headers['Authorization'] !== null || axios.defaults.headers['Authorization'] === undefined) {
  axios.defaults.headers['Authorization'] = localStorage.getItem('accessToken')
}
export default {
  SET_BEARER(state, accessToken) {
    // Set axios Bearer
    axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`
    // Set Bearer in localstorage
    localStorage.setItem('accessToken', `Bearer ${accessToken}`)
  },
}
