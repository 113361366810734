/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */

import * as html2canvas from 'html2canvas'

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

// get an image blob from url using fetch
let getImageBlob = function (url) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    let resposne = await fetch(url)
    let blob = resposne.blob()
    resolve(blob)
  })
}

// convert a blob to base64
let blobToBase64 = function (blob) {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = function () {
      let dataUrl = reader.result
      resolve(dataUrl)
    }
    reader.readAsDataURL(blob)
  })
}

// combine the previous two functions to return a base64 encode image from url
let getBase64Image = async function (url) {
  let blob = await getImageBlob(url)
  let base64 = await blobToBase64(blob)
  return base64
}

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  //New Code
  return new Blob([ab], { type: mimeString })
}

function getCloudfrontUrlFromS3(url) {
  // Remplace s3 URl with clo
  url = url.replace('https://s3.amazonaws.com/hoverlay-us-east-1/', 'https://d1e6lju4iii3se.cloudfront.net/')
  url = url.replace('https://hoverlay-us-east-1.s3.amazonaws.com/', 'https://d1e6lju4iii3se.cloudfront.net/')
  url = url.replace('https://s3.amazonaws.com/hoverlay-sandbox/', 'https://dvl0c1tetlb59.cloudfront.net/')
  url = url.replace('https://hoverlay-sandbox.s3.amazonaws.com/', 'https://dvl0c1tetlb59.cloudfront.net/')
  url = url.replace('https://s3.amazonaws.com/hoverlay-development/', 'https://d1mqm7isqh6o1p.cloudfront.net/')
  url = url.replace('https://hoverlay-development.s3.amazonaws.com/', 'https://d1mqm7isqh6o1p.cloudfront.net/')
  return url
}

/**
 * Load an image from a given URL
 * @param {String} url The URL of the image resource
 * @returns {Promise<Image>} The loaded image
 */
function loadImage(url) {
  /*
   * We are going to return a Promise which, when we .then
   * will give us an Image that should be fully loaded
   */
  return new Promise(resolve => {
    /*
     * Create the image that we are going to use to
     * to hold the resource
     */
    const image = new Image()
    /*
     * The Image API deals in even listeners and callbacks
     * we attach a listener for the "load" event which fires
     * when the Image has finished the network request and
     * populated the Image with data
     */
    image.addEventListener('load', () => {
      /*
       * You have to manually tell the Promise that you are
       * done dealing with asynchronous stuff and you are ready
       * for it to give anything that attached a callback
       * through .then a realized value.  We do that by calling
       * resolve and passing it the realized value
       */
      resolve(image)
    })
    /*
     * Setting the Image.src is what starts the networking process
     * to populate an image.  After you set it, the browser fires
     * a request to get the resource.  We attached a load listener
     * which will be called once the request finishes and we have
     * image data
     */
    image.src = url
  })
}

function flatDeep(arr) {
  return arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val) : val), [])
}

function checkYoutubeUrl(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

function cleanupNullKeys(obj) {
  var keysToRemove = []
  for (const [key, value] of Object.entries(obj)) {
    if (value == null) keysToRemove.push(key)
  }
  keysToRemove.forEach(key => {
    delete obj[key]
  })
  return obj
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

function htmlToImage(dom) {
  return new Promise(resolve => {
    html2canvas(dom).then(canvas => {
      var src = canvas.toDataURL()
      resolve(src)
    })
  })
}

function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName
  return theBlob
}

function getRandomFloat(min, max, decimals) {
  const str = (Math.random() * (max - min) + min).toFixed(decimals)

  return parseFloat(str)
}

function rgbToHex(r, g, b) {
  return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export {
  toBase64,
  humanFileSize,
  checkYoutubeUrl,
  isJsonString,
  dataURItoBlob,
  loadImage,
  getCloudfrontUrlFromS3,
  flatDeep,
  htmlToImage,
  cleanupNullKeys,
  getBase64Image,
  blobToFile,
  getRandomFloat,
  rgbToHex,
  hexToRgb,
}
