/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'
// eslint-disable-next-line
import { setHobjects } from '../../fake-db/data/data-list/index.js'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import router from '@/router'

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // Change firebase Persistence
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)

        // If success try to login
        .then(function () {
          dispatch('login', newPayload)
        })

        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    } else {
      // Try to login
      dispatch('login', newPayload)
    }
  },
  login({ commit, state, dispatch }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })

      return false
    }

    // Try to sigin
    firebase
      .auth()
      .signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)

      .then(
        (result) => {
          // Set FLAG username update required for updating username
          let isUsernameUpdateRequired = false

          // if username is provided and updateUsername FLAG is true
          // set local username update FLAG to true
          // try to update username
          if (payload.updateUsername && payload.userDetails.displayName) {
            isUsernameUpdateRequired = true

            dispatch('updateUsername', {
              user: result.user,
              username: payload.userDetails.displayName,
              notify: payload.notify,
              isReloadRequired: true,
            })
          }

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          // if username update is not required
          // just reload the page to get fresh data
          // set new user data in localstorage
          if (!isUsernameUpdateRequired) {
            router.push(router.currentRoute.query.to || '/')
            commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
          }
        },
        (err) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      )
  },

  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.TwitterAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.GithubAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
  registerUser({ dispatch }, payload) {
    // create user using firebase
    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(
        () => {
          payload.notify({
            title: 'Account Created',
            text: 'You are successfully registered!',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          })

          const newPayload = {
            userDetails: payload.userDetails,
            notify: payload.notify,
            updateUsername: true,
          }
          dispatch('login', newPayload)
        },
        (error) => {
          payload.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        }
      )
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        const newUserData = Object.assign({}, payload.user.providerData[0])
        newUserData.displayName = payload.displayName
        commit('UPDATE_USER_INFO', newUserData, { root: true })

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/')
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getUser()
        .then((response) => {
          commit('UPDATE_USER_INFO', response.data, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateLayer({ commit }, layer) {
    return new Promise((resolve, reject) => {
      jwt
        .updateLayer(layer)
        .then((response) => {
          commit('UPDATE_LAYER_INFO', response.body, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      jwt
        .updateUser(user)
        .then((response) => {
          commit('UPDATE_USER_INFO', response.body, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUserPhoto({ commit }, photo) {
    return new Promise((resolve, reject) => {
      console.log(photo)
      jwt
        .updateUserPhoto(photo)
        .then((response) => {
          commit('UPDATE_USER_INFO', response.body, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteUserPhoto({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteUserPhoto()
        .then((response) => {
          commit('UPDATE_USER_INFO', response)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // Password
  forgotPassword({ commit }, params) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPassword(params)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  newPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .newPassword(payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        jwt
          .login(payload.userDetails.email, payload.userDetails.password)
          .then((response) => {
            console.log(response.body)
            // If there's user data in response
            if (response.body && response.body.user && response.body.jwt) {
              // Set bearer token in axios
              commit('SET_BEARER', response.body.jwt)
              // Update user details
              commit('UPDATE_USER_INFO', response.body.user, { root: true })
              // Update layer details
              commit('UPDATE_LAYER_INFO', response.body.layers[0], { root: true })
              if (response.body.user.state != 'active') {
                try {
                  router.push('/email-confirmation-pending')
                } catch (e) {
                  console.log(e)
                }
              }
              // Navigate User to homepage
              else router.push(router.currentRoute.query.to || '/')
              resolve(response)
            } else {
              reject({ message: 'Wrong Email or Password' })
            }
          })
          .catch((error) => {
            console.log(error)
            // console.log(error.response)
            // console.log(error.response.status)
            // console.log(error.response.text)
            reject({ message: 'Wrong Email or Password' })
          })
      } catch (e) {
        console.log(e)
      }
    })
  },
  registerUserJWT({ commit }, payload) {
    console.log('payload')
    console.log(payload)

    const { username, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." })
      }

      jwt
        .registerUser(username, email, password)
        .then((response) => {
          // If register was successfull make the user login automatically
          jwt
            .login(email, password)
            .then((response) => {
              console.log(response.data)

              // If there's user data in response
              if (response.data && response.data.userInfo && response.data.userInfo.jwt) {
                // Set bearer token in axios
                commit('SET_BEARER', response.data.userInfo.jwt)

                // Update user details
                commit('UPDATE_USER_INFO', response.data.userInfo, { root: true })

                console.log('response.data.layerInfo')
                console.log(response.data.layerInfo)
                // Update layer details
                commit('UPDATE_LAYER_INFO', response.data.layerInfo, { root: true })
                resolve(response)
              } else {
                reject({ message: 'Wrong Email or Password' })
              }
            })
            .catch((error) => {
              console.log(error)
              // console.log(error.response)
              // console.log(error.response.status)
              // console.log(error.response.text)
              reject({ message: 'Wrong Email or Password' })
            })

          resolve(response)
        })
        .catch((error) => {
          console.log('error')
          console.log(error.response.data)
          reject(error.response.data)
        })
    })
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response)
      })
    })
  },
}
