import Uploader from '@/assets/js/rails-activestorage/Uploader.js'
import * as Utils from '@/assets/js/utils/utils.js'

import { ApiUrl } from '@/apiConfig.js'

function isImageAnchor(anchor_pid) {
  console.log(this.anchors.find(anchor => anchor.pid == anchor_pid))
  if (this.anchors.find(anchor => anchor.pid == anchor_pid && anchor.anchor_type == 'image')) {
    console.log('isImageAnchor')
    return true
  } else {
    return false
  }
}

function getUrlContentLength(url) {
  return new Promise(resolve => {
    require('axios')
      .create()
      .head(url)
      .then(response => {
        resolve(parseInt(response.headers['content-length']))
      })
      .catch(error => {
        // console.log(error)
        resolve(0)
      })
  })
}

function spacesComparer(otherSpaces) {
  return function (current) {
    return (
      otherSpaces.filter(function (other) {
        return other.pid == current.pid
      }).length == 0
    )
  }
}

function getUserRole() {
  return JSON.parse(localStorage.getItem('userInfo')).role
}

function getLayerPlanName() {
  return JSON.parse(localStorage.getItem('layerInfo')).plan.display_name
}

function getMaxUploadFileSize() {
  let PlanCapa = JSON.parse(localStorage.getItem('layerInfo')).plan_capabilities.find(
    planCapa => planCapa.name == 'max_upload_size'
  )
  if (PlanCapa && PlanCapa.value) return `${PlanCapa.value}Mb`
  else return '20Mb'
}

function isLayerSettingsEnable() {
  let PlanCapa = JSON.parse(localStorage.getItem('layerInfo')).plan_capabilities.find(
    planCapa => planCapa.name == 'advanced_layer_settings_editing'
  )
  if (PlanCapa) return true
  else return false
}

function isSpaceSettingsEnable() {
  let PlanCapa = JSON.parse(localStorage.getItem('layerInfo')).plan_capabilities.find(
    planCapa => planCapa.name == 'advanced_space_settings_editing'
  )
  if (PlanCapa) return true
  else return false
}

function isUnityAssetBundleUploadEnable() {
  let PlanCapa = JSON.parse(localStorage.getItem('layerInfo')).plan_capabilities.find(
    planCapa => planCapa.name == 'unity_asset_bundle_upload'
  )
  if (PlanCapa) return true
  else return false
}

function isHobjectAbilitiesEnable() {
  let PlanCapa = JSON.parse(localStorage.getItem('layerInfo')).plan_capabilities.find(
    planCapa => planCapa.name == 'advanced_abilities_editing'
  )
  if (PlanCapa) return true
  else return false
}

function isSequencingEnable() {
  let PlanCapa = JSON.parse(localStorage.getItem('layerInfo')).plan_capabilities.find(
    planCapa => planCapa.name == 'sequence_editing'
  )
  if (PlanCapa) return true
  else return false
}

function getLayerInfo() {
  return JSON.parse(localStorage.getItem('layerInfo'))
}

function directUpload(file, controller) {
  const uploader = new Uploader(file, `${ApiUrl}/rails/active_storage/direct_uploads`, controller)
  return uploader.upload()
}

async function createHobject(store, vs, hobject) {
  if (hobject.data) {
    // glb
    if (hobject.model_identifier == 'hoverlay.core.3dModel.1.0' && hobject.compress_glb == true) {
      try {
        var response = await store.dispatch('hoverlay/compressGlb', hobject.data)
        hobject.data = Utils.blobToFile(response, hobject.data.name)
      } catch (e) {
        console.error(e)
      }
      // others
    }
    if (hobject.data) {
      const signed_id = await directUpload(hobject.data, Uploader.directUploadWillStoreFileWithXHR)
      hobject['asset'] = signed_id
      delete hobject.data
    }
  }
  try {
    var newlyCreatedHobject = await store.dispatch('hoverlay/addHobject', hobject)
  } catch (error) {
    console.log(error)
    console.log(error.name)
    if (error.name == 'TypeError') {
      vs.notify({
        title: 'Connection is too slow',
        text: 'Try again from a different connection.',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
        fixed: true,
        click: () => {
          // Secondary function
        },
      })
    } else
      vs.notify({
        title: 'Error',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
  }
  //Check if we have the corresponding model in the webapp datastorage
  var model = store.state.hoverlay.models.filter(model => model.pid === newlyCreatedHobject.model_pid)[0]

  if (!model) {
    try {
      await store.dispatch('hoverlay/getLayerContent')
    } catch (error) {
      console.log(error.message)
      vs.notify({
        title: 'Error',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    } finally {
    }
    // if we already have the model in storage no need to fetch the database
  } else {
    vs.notify({
      color: 'success',
      title: 'Success',
      text: 'Your Custom object has been created ! ',
    })
  }
  return newlyCreatedHobject
}
async function createNewNodeInSpace(store, vs, router, hobject, space, node, anchor) {
  // // // // // // // // // // // // // // // //
  // Prepare payload to add a new node in space
  // // // // // // // // // // // // // // // //
  try {
    var params = {}

    // Add hobject pid to params payload
    if (!hobject.pid) hobject = await createHobject(store, vs, hobject)
    params.hobject_pid = hobject.pid

    // Add Anchor pid to params payload
    params.anchor_pid = anchor.pid

    params.angle_x = node.angle_x
    params.angle_y = node.angle_y
    params.angle_z = node.angle_z
    params.quaternion_x = node.quaternion_x
    params.quaternion_y = node.quaternion_y
    params.quaternion_z = node.quaternion_z
    params.quaternion_w = node.quaternion_w
    params.x = node.x
    params.y = node.y
    params.z = node.z
    params.scale = node.scale
    // Add space pid to params payload
    params.pid = space.pid
    console.log(params)
    // Call /space/{pid}/node
    await store.dispatch('hoverlay/createNodeInSpace', params)

    router.push(`/space/${space.pid}?anchor=${anchor.pid}`).catch(err => {
      console.log(err)
    })
  } catch (e) {
    console.error(e)
  }
}

export {
  getUrlContentLength,
  isImageAnchor,
  getUserRole,
  getLayerInfo,
  getLayerPlanName,
  spacesComparer,
  getMaxUploadFileSize,
  isLayerSettingsEnable,
  isSequencingEnable,
  isHobjectAbilitiesEnable,
  isSpaceSettingsEnable,
  isUnityAssetBundleUploadEnable,
  directUpload,
  createNewNodeInSpace,
  createHobject,
}
