/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// Help use same ref on more than one element.
import 'vue-multi-ref'

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// mock
import './fake-db/index.js'

// Theme Configurations
import '../themeConfig.js'

// Firebase
import '@/firebase/firebaseConfig'

// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)

// ACL
import acl from './acl/acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Vuexy Admin Filters
import './filters/filters'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

// Vue Form
import VueFormulate from '@braid/vue-formulate'
import VueFormulateExtended from 'vue-formulate-extended'

Vue.use(VueFormulate, {
  errorHandler: 'ferrors',
  plugins: [
    VueFormulateExtended({
      features: {
        formEvents: true, // by-default
        enforceNumber: true,
      },
    }),
  ],
  classes: {
    input: (context, classes) => {
      // console.log('CLA A A A A A A A')
      // console.log(context.type)
      // console.log(classes)
      if (context.type == 'text' || context.type == 'select' || context.type == 'number')
        return 'vs-inputx vs-input--input normal'
      // if (context.type == 'checkbox') return 'vs-checkbox--input'
      // return classes.concat(['mytheme-wrapper', `mytheme-wrapper--${context.type}`])
    },
  },
})

// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// Color picker
import { ColorPicker, ColorPanel } from 'one-colorpicker'
Vue.use(ColorPanel)
Vue.use(ColorPicker)

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, { inject: false })
// A filter for Vuejs to truncate string
var VueTruncate = require('vue-truncate-filter')
Vue.use(VueTruncate)

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCWAaBJsI1234TI18PITVy7p0Qb6ht123',
    // key: 'AIzaSyDqMLCizHJG9XhVVau0xrMTMpVh-kcUwJE',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
})

Vue.directive('lowercase', {
  update: function(el) {
    el.value = el.value.toLowerCase()
  },
})

// EventBus
// Global event bus as instance property
Vue.prototype.$eventBus = new Vue()

// vue2-leaflet，

// Import the Icon
import { Icon, latLng } from 'leaflet'
// Import the whole Leaflet CSS
import 'leaflet/dist/leaflet.css'
// Resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.use(latLng)

import VueQr from 'vue-qr'
Vue.component('vue-qr', VueQr)

// import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
// css
// import "leaflet/dist/leaflet.css";

// Vue.component("l-map", LMap);
// Vue.component("l-tile-layer", LTileLayer);
// Vue.component("l-marker", LMarker);
// Vue.component("l-popup", LPopup);
// Vue.component("l-icon", LIcon);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: 'G-5F4NLMQQ9R' },
  params: { send_page_view: true },
})

Vue.config.productionTip = false

import * as Utils from '@/assets/js/utils/utils.js'

Vue.mixin({
  methods: {
    getCloudfrontUrlFromS3(url) {
      if (url) return Utils.getCloudfrontUrlFromS3(url)
    },
  },
})

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App),
}).$mount('#app')
