var SwaggerDefinition = require('@/http/swagger/openapi.json') //with path

const ApiKey = '0d79f5cb-a857-4d7f-863b-f7a053ca52be'

const ApiUrl = 'https://hov-mrs-prod.herokuapp.com'
// const ApiUrl = 'https://hov-mrs-sand.herokuapp.com'
// const ApiUrl = 'https://hov-mrs-stag.herokuapp.com'
// const ApiUrl = 'http://localhost:3000'

SwaggerDefinition.servers[0].url = `${ApiUrl}/v1`

export { SwaggerDefinition, ApiKey, ApiUrl }
